import React, { memo, forwardRef } from 'react'
import Icon from '../src/Icon'

const svgPaths16 = [
  'M15 10c.495 0 .933.379.993.882L16 11v.505c0 1.461-3.524 2.45-7.707 2.493L8 14c-4.31 0-8-1-8-2.495V11c0-.561.466-1 1-1 .895 0 3 1 7 1l.381-.003C12.135 10.937 14.134 10 15 10zm-4-8c1.13 0 2.02 2.153 2.671 6.46-1.063.266-2.644.652-4.887.727l-.403.01L8 9.2c-2.664 0-4.488-.444-5.673-.74C2.98 4.153 3.87 2 5 2c2 0 1.329 2 3 2s1-2 3-2z'
]
const svgPaths20 = [
  'M18.5 13c1.118 0 1.466.534 1.498 1.366L20 14.5v.5c0 1.945-5.69 3-10 3S0 16.945 0 15v-.5c0-.908.323-1.5 1.5-1.5.895 0 3.5 1.2 8.5 1.2l.411-.003C15.143 14.134 17.631 13 18.5 13zm-5-10c1.725 0 2.954 2.729 3.688 8.186-1.455.263-3.805.72-6.392.801l-.434.01L10 12c-2.896 0-5.585-.524-7.189-.814C3.546 5.73 4.775 3 6.5 3 8.6 3 8.329 5.5 10 5.5S11.5 3 13.5 3z'
]

export const HatIcon = memo(
  forwardRef(function HatIcon(props, ref) {
    return (
      <Icon
        svgPaths16={svgPaths16}
        svgPaths20={svgPaths20}
        ref={ref}
        name="hat"
        {...props}
      />
    )
  })
)
